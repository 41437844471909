@use 'sass:map';
@import "variables";
@import "color-palettes";

/*
modified items: please keep a list
  - material form field
  - material dialog
  - material button
  - material menu
  - material select
  - material accordion (expansion panel)
  - material checkbox
*/

// MARK: Form Field
mat-form-field {
  width: 100%;

  &.form-input {
    font-size: .875rem;
    font-family: $font-family !important;
    line-height: 1rem;
  }
}

html {
  --mdc-filled-text-field-caret-color: #2ea2f8;
  --mdc-filled-text-field-focus-active-indicator-color: #2ea2f8;
  --mdc-filled-text-field-focus-label-text-color: #2ea2f8;
  --mdc-filled-text-field-container-color: #fff;
  --mdc-filled-text-field-input-text-color: #1F1F1F; // map.get($ink-palette, 900)
  --mdc-filled-text-field-label-text-font: "Open Sans", serif;
  --mdc-filled-text-field-label-text-size: 0.875rem;
  --mat-form-field-container-text-font: "Open Sans", serif;
  --mat-form-field-subscript-text-font: "Open Sans", serif;
}

.mat-mdc-text-field-wrapper.mdc-text-field--filled {
  --mdc-filled-text-field-active-indicator-height: 0px;
  --mdc-filled-text-field-focus-active-indicator-height: 0px;
  --mdc-filled-text-field-container-shape: 4px;
  border-radius: var(--mdc-filled-text-field-container-shape);
  border: $form-input-border;

  &:not(.mdc-text-field--disabled) {
    &.mdc-text-field--focused {
      border: 1px solid $form-focus-border-color;
    }
  
    &.mdc-text-field--invalid {
      border: 1px solid map.get($error-palette, 500);
    }
  }
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0;
}

.mat-mdc-text-field-wrapper {
  .mat-mdc-form-field-flex .mat-mdc-floating-label {
    top: 25px;
  }

  &:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
    padding-top: 23px;
    padding-bottom: 9px;
    min-height: unset;
  }

  .mat-mdc-select-arrow-wrapper {
    height: 16px;
    align-items: flex-end;
  }
}


// MARK: Dialog
.mat-mdc-dialog-container {
  --mdc-dialog-container-shape: 8px !important;
  --mdc-dialog-subhead-color: #1f1f1f;
  --mdc-dialog-supporting-text-color: #1f1f1f;
}

.mat-mdc-dialog-container {
  --mdc-dialog-subhead-font: "Open Sans", serif;
  --mdc-dialog-subhead-line-height: 1.45;
  --mdc-dialog-subhead-size: 22px;
  --mdc-dialog-subhead-weight: 600;
  --mdc-dialog-subhead-tracking: normal;
  --mdc-dialog-supporting-text-font: "Open Sans", serif;
  --mdc-dialog-supporting-text-line-height: 20px;
  --mdc-dialog-supporting-text-size: 14px;
  --mdc-dialog-supporting-text-weight: 400;
  --mdc-dialog-supporting-text-tracking: 0.0178571429em;
}

.mdc-dialog__container {
  min-width: 450px !important;
  max-width: 1100px !important;
}

.mdc-dialog__title {
  padding: 0 32px 32px 32px !important;
}

.mdc-dialog__content {
  padding: 0 32px 32px 32px !important;
  max-height: 70vh;
}

.mdc-dialog__actions {
  border-top: solid 1px $border-color !important;
  padding: 16px !important;
  
  .move-right {
    margin-left: auto;
    margin-right: 0px;
  
    button {
      margin-left: 8px;
    }
  }
}

// MARK: Button
.mdc-button {
  font-family: $font-family;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: normal;
  text-align: center;
}

.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #0073d0;
  --mat-mdc-button-persistent-ripple-color: #0073d0;
  --mat-mdc-button-ripple-color: #0073d01a;
}

.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #0073d0;
  &:disabled {
    --mdc-filled-button-disabled-container-color: #0073D040; // map.get($primary-palette, 100)
    --mdc-filled-button-disabled-label-text-color: #ffffff;
  }
}

.mat-mdc-outlined-button {
  background-image: linear-gradient(to top, #f2f4f7, $white) !important;
  border: $form-input-border !important;
  &.mat-primary {
    color: #495057;
    --mdc-outlined-button-label-text-color: #495057;
    --mat-mdc-button-persistent-ripple-color: #0073d0;
    --mat-mdc-button-ripple-color: #0073d01a;
  }
}

.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #0073d0;
}

html {
  --mat-icon-color: #777E85; // map.get($ink-palette, 600);
}

.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #0073d0;
  --mat-mdc-button-persistent-ripple-color: #0073d0;
  --mat-mdc-button-ripple-color: #0073d01a;
}

// MARK: Menu
.mat-mdc-menu-content .mat-mdc-menu-item span.mat-mdc-menu-item-text {
  font-family: $font-family;
  font-size: 14px;
  font-weight: $font-weight-bold;
  text-align: center;
}

// MARK: Select
mat-select.mat-mdc-select {
  font-family: $font-family;
  font-size: 14px;
}

mat-option {
  ngx-mat-select-search {
    input.mat-select-search-input {
      font-family: $font-family !important;
      font-size: 14px !important;
    }
  }
}

mat-option.mat-mdc-option .mdc-list-item__primary-text,
.checkbox-section label {
  font-family: $font-family;
  font-size: 14px;
  font-weight: $font-weight-bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.375;
  letter-spacing: normal;
  color: $form-input-color;
  vertical-align: top;
}

// MARK: Accordion
mat-accordion {
  mat-expansion-panel {
    border: 1px solid #D1D6DB;
    border-radius: 12px!important;
    box-shadow: none!important;

    mat-panel-title {
      font-family: $font-family;
      font-weight: $font-weight-bolder;
      font-size: 16px;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: normal;
      line-height: 1.5;
      color: $almost-black;
    }
  }
  
  >.mat-expansion-panel-spacing:last-child {
    margin-top: 10px;
  }
}

// MARK: Checkbox
html,
.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: #D1D6DB;
  --mdc-checkbox-disabled-unselected-icon-color: #D1D6DB;
  --mdc-checkbox-selected-focus-icon-color: #3185FC;
  --mdc-checkbox-selected-hover-icon-color: #3185FC;
  --mdc-checkbox-selected-icon-color: #3185FC;
  --mdc-checkbox-selected-pressed-icon-color: #3185FC;
  --mdc-checkbox-unselected-focus-icon-color: #ACB3B9;
  --mdc-checkbox-unselected-hover-icon-color: #ACB3B9;
  --mdc-checkbox-unselected-icon-color: #D1D6DB;
  --mdc-checkbox-unselected-pressed-icon-color: #D1D6DB;
  --mdc-checkbox-selected-focus-state-layer-color: #3185FC;
  --mdc-checkbox-selected-hover-state-layer-color: #3185FC;
  --mdc-checkbox-selected-pressed-state-layer-color: #3185FC;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
  --mdc-theme-text-primary-on-background: #1f1f1f;
  --mdc-checkbox-state-layer-size: 36px;
}

.mat-mdc-checkbox .mdc-checkbox {
  padding: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2);
  margin: 0;
  flex: 0 0 16px;
  width: 16px;
  height: 16px;
}

.mat-mdc-table .mat-mdc-checkbox .mdc-checkbox {
  // Set checkbox margin to equal and opposite the padding of the checkbox when in a table
  margin: 0 calc((var(--mdc-checkbox-state-layer-size) - 16px) / -2);
}

.mat-mdc-checkbox .mdc-form-field {
  font-family: $font-family;
  font-size: 14px;
  line-height: 1.375;
  font-weight: 400;
  letter-spacing: normal;
}

.mat-mdc-checkbox .mdc-checkbox__background {
  top: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  left: calc((var(--mdc-checkbox-state-layer-size) - 16px) / 2) !important;
  width: 16px;
  height: 16px;
  border: 1px solid #D1D6DB;
  border-radius: 4px;
  background: linear-gradient(to top, #f2f4f7, #ffffff);
}

.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: $form-input-disabled-color;
}

.mat-mdc-checkbox.mat-mdc-checkbox-checked label {
  font-weight: 600;
}

.mat-mdc-checkbox {
  .mdc-checkbox__native-control {
    &:enabled:checked,
    &:enabled:indeterminate,
    &[data-indeterminate=true]:enabled {
      &~.mdc-checkbox__background {
        border-width: 2px;
        background-image: linear-gradient(to top, $form-checkbox-background-color, $form-checkbox-background-color);
        border-color: $form-checkbox-background-color !important;
      }
    }
    
    &[disabled]:checked,
    &[disabled]:indeterminate,
    &[data-indeterminate=true][disabled] {
      &~.mdc-checkbox__background {
        border-width: 2px;
        background: #0073D060 !important;
        border-color: transparent !important;
      }
    }
  }
  .mdc-checkbox__native-control[disabled]:not(:checked):not(:indeterminate):not([data-indeterminate=true])~.mdc-checkbox__background {
    background-image: linear-gradient(to top, $form-checkbox-background-color-disabled, #D1D6DB80);
  }
}