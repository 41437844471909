/* You can add global styles to this file, and also import other style files */
@use 'sass:map';
@import "variables";
@import url(https://fonts.googleapis.com/css?family=Open+Sans);
@import "../../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import "~bootstrap/dist/css/bootstrap.css";
@import "material-overrides";

body {
  background-color: $body-background;
  font-family: $font-family;
}

.clickable-pointer {
  cursor: pointer;

  &:hover {
    color: $primary;
  }
}

// MARK: Native Checkbox
.checkbox-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  input[type=checkbox],
  .mat-pseudo-checkbox {
    cursor: pointer;
    -webkit-appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: solid 1px $form-checkbox-background-color-disabled;
    background-image: linear-gradient(to top, $gradient-color, $white);

    &:checked,
    &:indeterminate,
    &.mat-pseudo-checkbox-checked {
      border-color: $form-checkbox-background-color;
      background-image: linear-gradient(to top, $form-checkbox-background-color, $form-checkbox-background-color);
      -webkit-transform: translate(-0%,-0%);
      -moz-transform: translate(-0%,-0%);
      -ms-transform: translate(-0%,-0%);
      transform: translate(-0%,-0%);
      &:disabled {
        background-image: linear-gradient(to top, #0073D060, #0073D060);
        border-color: #0073D020;
      }
      &:disabled + label {
        font-weight: $font-weight-bolder;
        color: $form-input-disabled-color;
      }
    }
    &:checked:after,
    &:indeterminate:after {
      color: $white;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%,-50%);
      -moz-transform: translate(-50%,-50%);
      -ms-transform: translate(-50%,-50%);
      transform: translate(-50%,-50%);
    }    
    &:checked:after {
      content: "\2713";
    }
    &:checked + label {
      color: $header-font-color;
      font-weight: $font-weight-bold;
    }
    &:disabled {
      cursor: default;
      background-image: linear-gradient(to top, $form-checkbox-background-color-disabled, #D1D6DB80);
    }
    &:disabled + label {
      color: $form-input-disabled-color;
    }
    &:focus {
      outline: none;
      box-shadow: 0 0 5px 1px $form-focus-border-color;
    }
    &:indeterminate:after {
      content: "\02014";
      top: 45%;
    }
  }  
  &.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
    color: $header-font-color;
  }
  .error-margin {
    margin-left: 8px;
  }
}

.title-container {
  min-width: 450px;

  .title {
    font-family: $font-family;
    font-weight: $font-weight-bolder;
    font-size: 22px;
    line-height: 1.45;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: map.get($ink-palette, 900);;
  }

  .description {
    font-family: $font-family;
    font-weight: $font-weight-bold;
    font-size: 12px;
    line-height: 1;
    font-style: normal;
    font-stretch: normal;
    letter-spacing: normal;
    color: map.get($ink-palette, 700);
    margin: 10px 0;
  }
}

.form-buttons {
  display: flex;
  margin: 20px 10px 0;

  .move-right {
    margin-left: auto;
    margin-right: 0px;
  }
}

.breadcrumbs {
  display: flex;
  column-gap: 8px;
  font-family: $font-family;
  font-weight: $font-weight-bolder;
  font-style: normal;
  font-size: 26px;
  line-height: 36px;

  .active-breadcrumb {
    color: $almost-black;
  }

  .idle-breadcrumb {
    color: map.get($ink-palette, 700);
    
    &:hover {
      cursor: pointer;
      text-decoration-line: underline;
    }
  }
}

.entity-details-wrapper {
  display: flex;
  width: 100%;
  
  .container {
    transition: all 0.3s;
    padding: 2rem 0 2rem 0;

    .content {
      width: 100%;
      padding-left: 64px;
      padding-right: 64px;
      @media screen and (max-width: $break-small){
        padding-left: 64px;
      }

      .entity-details-content {
        width: 100%;
        box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
        transition: 0.3s;
        border-radius: 6px;
        border: solid 1px $border-color;
        position: relative;
        padding-top: 20px;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 24px;
        margin-top: 20px;
        background: $white;
        min-width: 700px;
      
        .row {
          margin: 10px 0;

          mat-accordion {
            margin: 10px 0;
          }
        }
      
        .details-container {
          background-clip: padding-box;
          border: $form-input-border;
          border-radius: .25rem;
          min-height: 3.5rem;
      
          .key,
          .value {      
            font-family: $font-family;
            line-height: 1.2;
            color: $form-input-color;
          }
      
          .key {
            font-size: 12px;
            margin: 10px;
          }
      
          .value {
            font-size: 14px;
            margin: 10px;
          }
        }
      
        .subtitle-container {
          margin-top: 20px;
          margin-bottom: 10px;
          
          .subtitle {
            font-family: $font-family;
            font-weight: $font-weight-bold;
            font-size: 16px;
            font-stretch: normal;
            font-style: normal;
            letter-spacing: normal;
            line-height: 1.5;
            color: $almost-black;
          }
        }
      }
      
      .move-right {
        display: flex;
        align-items: center;
        justify-content: flex-end;

        button {
          .mat-icon {
            height: 40px;
          }
        }
      }
    }
  }
}

// MARK: Form Field Customization
.form-field--thin, .search-wrapper {
  align-items: center;
  
  .mat-mdc-form-field-infix {
    padding-top: 8px !important;
    padding-bottom: 8px !important;
  }

  .mat-mdc-input-element {
    height: 21px;
  }

  .mat-mdc-text-field-wrapper .mat-mdc-select-arrow-wrapper {
    align-items: center;
  }
}

.form-field--thinner {
  align-items: center;

  .mat-mdc-form-field-infix {
    padding-top: 4px !important;
    padding-bottom: 4px !important;
  }

  .mat-mdc-input-element {
    height: 20px;
  }
}

.search-wrapper .search-field {
  font-family: $font-family;
  font-size: 14px !important;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: 1.5;
  letter-spacing: normal;

  .prefix-icon {
    padding: 10px;
    width: 16px;
    height: 16px;
  }
}

// MARK: Legacy Filter Dropdown
.legacy-filter-dropdown {
  .mat-mdc-text-field-wrapper.mdc-text-field--filled {
    border: 1px solid #f5f5f6; // Same color as app background so UI doesn't shift when border is added on hover/focus
  
    &:hover:not(.mdc-text-field--focused, .mdc-text-field--invalid) {
      border: 1px solid map.get($ink-palette, 500);
    }
    
    &:not(.mdc-text-field--disabled) {
      --mdc-filled-text-field-container-color: #f5f5f6;
    }
  }
}

// MARK: Native Table
.mat-elevation-z8 {
  &.table-container,
  &.readonly-table-container {
    border-radius: 6px;
    box-shadow: 0 0 0 0;
    border: $border-box;
    background-color: $white;
    overflow: auto;
    max-height: 70vh;
    min-width: 680px;
    padding: 0 1rem 1rem 1rem;

    table {
      width: 100%;
      font-weight: 400;
      color: map.get($ink-palette, 900);

      thead tr {
        min-height: 36px;
        border-bottom: solid 1px map.get($ink-palette, 300);

        th {
          font-size: 10px;
          font-family: $font-family;
          font-weight: $font-weight-bolder;
          color: map.get($primary-palette, 900);
          word-wrap: break-word !important;
          white-space: nowrap;
          overflow-wrap: break-word;
          word-wrap: break-word;
          word-break: break-word;
          -ms-hyphens: auto;
          -moz-hyphens: auto;
          -webkit-hyphens: auto;
          hyphens: auto;
          line-height: 1;
          padding: 0 0.625rem 0 1rem;
  
          .mat-sort-header-arrow {
            margin: 0 10px 0;
          }

          th[aria-sort=descending] {
            .mat-sort-header-arrow {
              margin: 5px 10px 0;
            }
          }

          mat-sort-header-stem {
            display: none !important;
          }
        }  
      }
      
      tbody tr {
        border-bottom-width: 0;
        height: 36px;
        
        &:nth-child(even) {
          background-color: $table-even-color;
        }
        &.selected {
          background-color: map.get($primary-palette, 50);
        }
        td {
          font-size: 14px;
          font-family: $font-family;
          font-weight: $font-weight-bold;
          color: $table-font-color;
          padding: 0 0.625rem 0 1rem;
          border-bottom-width: 0;
        }
      }
    }

    // Scrollbar customization
    &::-webkit-scrollbar {
      width: 0.375rem;
      height: 0.375rem;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #bac1c7;
      border-radius: 0.1875rem;
    }

    // Scrollbar customization for firefox
    scrollbar-width: thin;
    scrollbar-color: #bac1c7 $white;
  }

  &.table-container {
    table {
      tbody tr {
        &:hover, &:focus {
          background-color: map.get($primary-palette, 50);
          outline: map.get($primary-palette, 500) auto .5px;
        }
      }
    }
  }

  &.readonly-table-container {
    .mat-mdc-table  {
      tr.mat-mdc-row {
        &:hover, &:focus {
          outline: none;

          &:nth-child(even) {
            background-color: $table-even-color;
          }

          &:not(:nth-child(even)){
            background-color: white;
          }
        }
      }
    }
  }
}